import React from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';


export default function DescriptionCalculator(props) {

    return (
        <div>
            <h3>Erklärung</h3>
            <Box display="flex" marginY={3} paddingX={5} style={{ backgroundColor: "#424242" }}>
                <Typography id="discrete-slider-always" style={{ fontSize: 14 }} gutterBottom>
                    <p align="justify">
                        Um kostengünstiger für dich Supportleistung bereitstellen zu können führen wir ein Support Kundenkonto ein. Bisher musstest du für eine Supportstunde <b style={{ fontSize: 16 }}>75€</b> zahlen.
                            Absofort kannst du bei uns vorab ein Supportkontigent mit einer Vergünstigung von bis zu <b style={{ fontSize: 16 }}>22%</b> einkaufen und darüber Aufträge an uns vergeben. Abgerechnet wird auf <b style={{ fontSize: 16 }}>0,5h-Basis</b>.
                        </p>
                    <p align="justify">
                        Mit dem Slider stellst du einfach dein gewünschtes Supportkontingent ein und siehst sofort eine Übersicht der Kosten und des gegebenen Rabatts.
                        In Zukunft kannst du über dieses Portal direkt eine Anfrage für eine Supportkontigent per Email an uns zusenden. Bis diese Funktion implementiert
                            ist bitten wir dich einfach deine Anfrage direkt an die <b style={{ fontSize: 16 }}>billing@capsloq.de</b> zu senden. Solltest du Fragen zur Preisgestaltung haben,
                            kannst du dich ebenfalls an die genannte Email-Adresse wenden.
                        </p>
                    <p align="justify">
                        Beachte bitte, dass das Supportkontingent nur für Supportaufträge genutzt werden kann. Du bist dir nicht sicher, ob dein Auftrag unter das Supportkontingent fällt, kannst du dich
                        gerne an uns wenden und wir finden die beste Lösung für dich!
                        </p>
                </Typography>
            </Box>
        </div>
    )
}