import * as React from "react";
import { Route } from 'react-router-dom';
import Calculator from "./pages/calculator";
import SupportKonto from "./pages/supportKonto";

export default [
    
    <Route exact path="/kalkulator" component={Calculator} />,
    <Route exact path="/konto" component={SupportKonto} />,


];