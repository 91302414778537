import * as React from "react";
import { Admin, Resource, fetchUtils } from 'react-admin';
import simpleRestProvider from './ra-strapi-rest';
import { createMuiTheme } from '@material-ui/core/styles';
import authProvider from './authProvider'
import Cookies from './helpers/Cookies';
import LoginPage from './loginPage';
import customRoutes from './customRoutes';
import Dashboard from './pages/Dashboard';
import CustomLayout from "./layout/customLayout";
import { TicketList } from "./pages/ticketList";
import { TicketShow } from "./pages/ticketShow";
import { TicketCreate } from "./pages/ticketCreate";
import BugReportIcon from '@material-ui/icons/BugReport';



const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = Cookies.getCookie('token')
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

const  uploadFields = ["media"];
// const  dataProvider = simpleRestProvider(baseUrl, httpClient, uploadFields);

const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, httpClient, uploadFields);

const App = () => (
  // customRoutes={customRoutes}
  <Admin
  disableTelemetry
  dashboard={Dashboard}
  theme={theme}
  loginPage={LoginPage}
  authProvider={authProvider}
  dataProvider={dataProvider}
  customRoutes={customRoutes}
  layout={CustomLayout}
  name="Supportleistung"
    
  >
      
    <Resource name="tickets" list={TicketList} show={TicketShow} create={TicketCreate} options={{ label: 'Ticket' }} icon={BugReportIcon} />
  </Admin>
);

const theme = createMuiTheme({
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
    primary: {
      light: '#b2fef7', 
      main: '#80cbc4', //Button und Slider
      dark: '#4f9a94',
      contrastText: '#000',
    },
    secondary: {
      light: '#98ee99',
      main: '#80cbc4', // Appbar
      dark: '#338a3e',
      contrastText: '#222222', // Appbar Schrift
    },
  },
});

export default App;