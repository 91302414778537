import * as React from "react";
import { Create, SimpleForm, RadioButtonGroupInput, FileField, TextInput, FileInput } from 'react-admin';

export const TicketCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="email" label="Kontakt Email-Adresse" />
            <TextInput source="title" label="Auftragstitel" />
            <TextInput source="description" label="Beschreibung" multiline style={{width:"100%"}} />
            <RadioButtonGroupInput source="priority" label="Priorität" choices={[
                { id: 'low', name: 'low' },
                { id: 'medium', name: 'medium' },
                { id: 'high', name: 'high' },
            ]} />
            <FileInput source="media" label="Datein zum Hochladen" multiple={true}>
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    </Create>
);