import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { List, Datagrid, TextField, EmailField, BooleanField, DateField, ArrayField, SingleFieldList, ChipField } from 'react-admin';


const useStyles = makeStyles((theme) => ({
    cell: {
        fontSize: 14,
        textAlign: 'center',
        // padding: theme.spacing(2),
    },
    header: {
        fontSize: 16,
        textAlign: 'left',
        // padding: theme.spacing(2),
    },

}));


export const TicketList = props => {
    const classes = useStyles();
    return(
    <List {...props}>
        <Datagrid rowClick="show"  >
            {/* <TextField source="id" /> */}
            <EmailField source="email" label="Kontakt" headerClassName={classes.header} cellClassName={classes.cell}/>
            <TextField source="title" label="Auftrag" headerClassName={classes.header} cellClassName={classes.cell}/>
            {/* <TextField source="description" label="Beschreibung" className={classes.root}/> */}
            <TextField source="priority" label="Priorität" headerClassName={classes.header} cellClassName={classes.cell}/>
            <BooleanField source="isAccepted" label="angenommen" headerClassName={classes.header} cellClassName={classes.cell}/>
            <BooleanField source="isDone" label="erledigt"  headerClassName={classes.header} cellClassName={classes.cell}/>
            <TextField source="date" label="bearbeitet am"  headerClassName={classes.header} cellClassName={classes.cell}/>
            <TextField source="text" label="Kommentar" headerClassName={classes.header} cellClassName={classes.cell}/>
            {/* <NumberField source="user.id" /> */}
            <DateField source="created_at" label="erstellt am" headerClassName={classes.header} cellClassName={classes.cell}/>
            {/* <DateField source="updated_at" /> */}
            <ArrayField source="media" label="Dateien" headerClassName={classes.header} cellClassName={classes.cell}>
                <SingleFieldList>
                    <ChipField source="id" />
                </SingleFieldList>
                </ArrayField>
        </Datagrid>
    </List>
);
}