// in src/MyLayout.js
import { Layout } from 'react-admin';
import CustomMenu from './customMenu';

const CustomLayout = (props) => <Layout
    {...props}
    // dashboard={Dashboard}
    // appBar={MyAppBar}
    menu={CustomMenu}
    // notification={MyNotification}
/>;

export default CustomLayout;