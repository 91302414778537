import React from 'react';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import BoughtHours from "../components/boughtHours";
import RemainHours from "../components/remainHours";
import UsedHours from "../components/usedHours";
import TaskAmount from '../components/taskAmount';
import Overview from '../components/overview';



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

export default function SupportKonto(props) {
    const classes = useStyles();

return (
    <div>
        <div className={classes.root}>
            {/* Oberer Teil */}
          <Grid container spacing={3}>
            <Grid item xs>
              <Paper className={classes.paper}  component={RemainHours}>Supportstunden: Insgesamt</Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paper} component={BoughtHours}>Supportstunden: Verbleibend</Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paper} component={UsedHours}>Supportstunden: Verbraucht</Paper>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paper} component={TaskAmount}>Aufträge: Erledigt</Paper>
            </Grid>
          </Grid>
          
          {/* Unterer Teil */}
          <Grid container spacing={6}>
            <Grid item xs>
              <Paper className={classes.paper} component={Overview}>Supportstunden: Verbraucht</Paper>
            </Grid>            
          </Grid>
        </div>
        <Button
            variant="contained"
            size="large"
            color="primary"
            target="_top"
            href="/"
            // rel="noopener noreferrer"
            style={{ marginTop: '10px' }}
        >
            <Typography variant="button" style={{ fontSize: 14 }}>
                Zurück
    </Typography>
        </Button>
    </div>
)

}