import React from 'react';
import SliderCalculator from '../components/sliderCalculator';
import DescriptionCalculator from '../components/descriptionCalculator';

//Style des SLiders


export default function Calculator(props) {
return(<div>
    <SliderCalculator/>
    <DescriptionCalculator/>
    </div>
    )

}