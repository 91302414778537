import * as React from "react";
import {
    FormWithRedirect,
    TextInput,
    SimpleFormIterator,
    ArrayInput,
    NumberInput,
    DateTimeInput,
    BooleanInput,
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';


const VisitorForm = props => (
    <FormWithRedirect
        {...props}
        render={formProps => (
            // here starts the custom form layout
            <form>
                <Box p="1em">
                    <Box display="flex">
                        <Box flex={2} mr="1em">

                            <Typography variant="h6" gutterBottom>Ticket Details</Typography>

                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <TextInput source="email" label="Kontakt Email" disabled fullWidth />
                                </Box>
                                <Box flex={1} ml="0.5em">
                                    <BooleanInput source="isAccepted" label="angenommen" disabled fullWidth />
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <TextInput source="title" label="Ticket Titel" disabled fullWidth />
                                </Box>
                                <Box flex={1} ml="0.5em">
                                    <BooleanInput source="isDone" label="erledigt" disabled fullWidth />
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <TextInput source="description" label="Beschreibung" disabled multiline fullWidth />
                                </Box>
                                <Box flex={1} ml="0.5em">
                                    
                                    <TextInput source="text" label="Kommentar" multiline disabled fullWidth />
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <DateTimeInput source="created_at" label="erstellt am" disabled fullWidth />
                                </Box>
                                <Box flex={1} ml="0.5em">
                                    <DateTimeInput source="date" label="erledigt am" disabled fullWidth />
                                </Box>
                            </Box>

                            <Typography variant="h6" gutterBottom>Hochgeladene Dateien</Typography>

                            <ArrayInput source="media" >
                                <SimpleFormIterator disableRemove disableAdd >
                                    <TextInput source="name" label="Datei Name" disabled/>
                                    <TextInput source="ext" label="Dateiformat" disabled/>
                                    <NumberInput source="size" label="Datei Größe in kB"disabled />
                                    <TextInput source="url" label="Download Url" type="url" multiline disabled/>
                                    <DateTimeInput source="created_at" label="hochgeladen am" disabled/>
                                </SimpleFormIterator>
                            </ArrayInput>


                        </Box>

                    </Box>
                </Box>
                {/* <Toolbar>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <SaveButton
                            saving={formProps.saving}
                            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                        />
                        <DeleteButton record={formProps.record} />
                    </Box>
                </Toolbar> */}
            </form>
        )}
    />
);
export default VisitorForm