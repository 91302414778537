import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BoughtHours from "../components/boughtHours";
import RemainHours from "../components/remainHours";
import UsedHours from "../components/usedHours";
import CardActions from '@material-ui/core/CardActions';
import CustomButton from "../components/customButton";
import SliderCalculator from "../components/sliderCalculator";
import Overview from "../components/overview";
import TaskAmount from "../components/taskAmount";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function Dashboard(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs>
                    <Card className={classes.root}>
                        {/* Die jeweilige Component wird übergeben die als Card im Dashboard angezeigt werden soll */}
                        <CardContent component={RemainHours} />
                        <CardActions>
                            {/* Hier muss nur die Url nach root zum weiterlesen von Dateils angegebn werden */}
                            <CustomButton data='/konto'/>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs>
                    <Card className={classes.root}>
                        <CardContent component={BoughtHours} />
                        <CardActions>
                            <CustomButton data='/konto'/>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs>
                    <Card className={classes.root}>
                        <CardContent component={UsedHours} />
                        <CardActions>
                            <CustomButton data='/konto'/>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={9}>
                    <Card className={classes.root } >
                        <CardContent component={SliderCalculator} />
                        <CardActions>
                            <CustomButton data='/kalkulator'/>
                        </CardActions>
                    </Card>
                    {/* <Paper className={classes.paper} component={Calculator}>xs=6</Paper> */}
                </Grid>
                <Grid item xs>
                    <Card className={classes.root } >
                        <CardContent component={TaskAmount} />
                        <CardActions>
                            <CustomButton data='/konto'/>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs>
                    <Card className={classes.root } >
                        <CardContent component={Overview} />
                        <CardActions>
                            <CustomButton data='/kalkulator'/>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}