import * as React from "react";
import { Button } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

export default function CustomButton(data) {

    //root Url definition
    let url ='/#'
    //auslesen der Übergabe Url und anfügen an root
    url+= Object.values(data)

    return (

                            <Button
                                size="small"
                                color="primary"
                                target="_top"
                                href={url}
                            >
                               Mehr
                                <ArrowRightAltIcon style={{ fontSize: 40 }}/>
                            </Button>


    );
}