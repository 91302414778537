import React from "react";
import { useQuery, useNotify, Loading } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';



const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
  }));
  


export default function RemainHours(props) {

    const classes = useStyles();
    const notify = useNotify();
  
    //Boilerplate how it should be done
    const { data: data, loading, error } = useQuery(
      {
        type: 'getOne',
        resource: 'profiles',
        //Backend handlet user id
        payload: { id: 'platzhalter' }
      },
      {
        onSuccess: ({ data }) => {

        },
        onFailure: (error) => notify(`Es ist ein Fehler aufgetreten beim laden deiner Daten: ${error.message}`, 'Error'),
  
      }
    )
    if (loading)
        return <Loading />

    return (
        <div className={classes.root}>
          <Card>
                <CardContent>
                    <div>
                        <div>Support: Verbleibend</div>
                        <div style={{ fontSize: 60 }}>
                            {/* Aufsummieren von duration */}
                            {data.customerInfo.reduce((a, b) => a + (b['duration'] || 0), 0)-
                            data.serviceInfo.reduce((a, b) => a + (b['duration'] || 0), 0)
                            }

                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
      );
}