import * as React from "react";
import { Show} from 'react-admin';
import VisitorForm from "../components/showLayout";

export const TicketShow = props => (
    <Show {...props}>
        <VisitorForm variant="filled" >
            {/* Show Ansicht wird als eigene Componenete verwendet unter ticketShow.js */}
        </VisitorForm>
    </Show>
);