import React from 'react';
import Input from '@material-ui/core/Input';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';

//Style des SLiders
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    margin: {
        height: theme.spacing(3),
    },
    //CSS Handler
    input: {
        width: 80,
    },
    slider: {
        width: "30%",
    },
}));

//Style für SLider und Values die unten angezeigt werden
const marks = [
    {
        value: 10,
        // label: '10 Std.',
    },
    {
        value: 20,
        // label: '20 Std.',
    },
    {
        value: 30,
        // label: '30 Std.',
    },
    {
        value: 40,
        // label: '40 Std.',
    },
    {
        value: 50,
        // label: '50 Std.',
    },
    {
        value: 60,
        // label: '60 Std.',
    },
    {
        value: 70,
        // label: '70 Std.',
    },
    {
        value: 80,
        // label: '80 Std.',
    },
    {
        value: 90,
        // label: '90 Std.',
    },
    {
        value: 100,
        // label: '100 Std. ',
    },
];

function valuetext(value) {
    return `${value} h`;
}

function valueLabelFormat(value) {
    return `${value} h`;
}

//   function valuetext(value) {
//     return `${value}°C`;
//   }

export default function SliderCalculator(props) {

    const classes = useStyles();
    // Handling Variables-------------------------------
    const [value, setValue] = React.useState(30);

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleInputChange = (event) => {
        setValue(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
        } else if (value > 100) {
            setValue(100);
        }
    };

    function valueCal(value) {
        //Basisder Berechnung ist Ausgewählte Stundenzahl * Stundenlohn * (100%-2% pro 10er Stufe)
        let h
        switch (value) {
            case 0:
                return 75
            case 10:
                h = value * 75 * (1 - 0.04)
                return h
            case 20:
                h = value * 75 * (1 - 0.06)
                return h
            case 30:
                h = value * 75 * (1 - 0.08)
                return h
            case 40:
                h = value * 75 * (1 - 0.10)
                return h
            case 50:
                h = value * 75 * (1 - 0.12)
                return h
            case 60:
                h = value * 75 * (1 - 0.14)
                return h
            case 70:
                h = value * 75 * (1 - 0.16)
                return h
            case 80:
                h = value * 75 * (1 - 0.18)
                return h
            case 90:
                h = value * 75 * (1 - 0.20)
                return h
            case 100:
                h = value * 75 * (1 - 0.22)
                return h
            default:
                return "Fehler"
        }
    };

    function valueOrigin(value) {
        //Basisder Berechnung ist Ausgewählte Stundenzahl * Stundenlohn * (100%-2% pro 10er Stufe)
        let h
        switch (value) {
            case 0:
                return 75
            case 10:
                h = value * 75
                return h
            case 20:
                h = value * 75
                return h
            case 30:
                h = value * 75
                return h
            case 40:
                h = value * 75
                return h
            case 50:
                h = value * 75
                return h
            case 60:
                h = value * 75
                return h
            case 70:
                h = value * 75
                return h
            case 80:
                h = value * 75
                return h
            case 90:
                h = value * 75
                return h
            case 100:
                h = value * 75
                return h
            default:
                return "Fehler"
        }
    };


    return (
        <div>
                    <h3>Kalkulator</h3>
                    <Box display="flex" m={0} p={3} style={{ backgroundColor: "#424242" }}>

                        <Box p={3} flexGrow={3}>
                            <Slider
                                value={typeof value === 'number' ? value : 0}
                                onChange={handleSliderChange}
                                aria-labelledby="input-slider"
                                defaultValue={30}
                                getAriaValueText={valuetext}
                                valueLabelFormat={valueLabelFormat}
                                step={10}
                                marks={marks}
                                valueLabelDisplay="on"
                            />
                        </Box>
                        <Box paddingY={3} paddingLeft={3}>
                            <Input
                                className={classes.input}
                                value={valueCal(value) + ' €'}
                                margin="dense"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                disableUnderline={true}
                                readOnly={true}
                                style={{ fontSize: 22, fontWeight: "bold" }}
                            />
                        </Box>

                        <Box p={0}>
                            <Input
                                className={classes.input}
                                value={`≙ ${valueOrigin(value) - valueCal(value)} € Rabatt`}
                                disableUnderline={true}
                                color={"secondary"}
                                margin="dense"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                readOnly={true}
                                style={{ color: 'red', width: 150 }}
                            />
                        </Box>
                        <Box p={2}>
                            <Button
                                variant="outlined"
                                size="large"
                                color="primary"
                                target="_top"
                                rel="noopener noreferrer"
                                href={`mailto:billing@capsloq.de`}
                                style={{ margin: '5px' }}
                            >
                                <Typography variant="button" style={{ fontSize: 14 }}>
                                    Sende Email
                            </Typography>
                            </Button>
                        </Box>
                    </Box>
        </div>
    )

}