import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
    main: { background: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)' },
    avatar: {
        background: 'url(https://storage.googleapis.com/avimporter-bucket/logo/logo-icon-left-white.png) center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: 80,
       
    },
    icon: { display: 'none' },
});

const CustomLoginForm = withStyles({
    button: { background: '#F15922' },
})(LoginForm);

const CustomLoginPage = props => (
    <div>
    <Login
        loginForm={<CustomLoginForm />}
        {...props}
        
    />
    <div><a href="https://capsloq.de/impressum">Impressum</a></div>
    </div>
);

export default withStyles(styles)(CustomLoginPage);